import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private apiCount = 0;

  public isloading$ = new BehaviorSubject(true);

  constructor() {}
  showLoader() {
    if (this.apiCount === 0) {
      this.isloading$.next(true);
    }
    this.apiCount++;
  }

  hideLoader() {
    this.apiCount--;
    if (this.apiCount === 0) {
      this.isloading$.next(false);
    }
  }
}
